<template>
<div>
    <div class="horizFlex">
        <button :class="buttonClass('pass')" @click="pass()">
            <div class="horizFlex">
            <img src="@/assets/CheckmarkGreen.svg" alt="" class="buttonImage">
            </div>
            
        </button>
        <button :class="buttonClass('maybe')" @click="maybe()">
            <div class="horizFlex">
            <img src="@/assets/YellowQuestionMark.svg" alt="" class="buttonImage">
            </div>
            
        </button>
        <button :class="buttonClass('fail')" @click="showReason=true" > 
            <div class="horizFlex" >
                <img src="@/assets/RejectRed.svg" alt="" class="buttonImage">
            </div>
        </button>
    </div>
    <div>
        <label for="">{{selectedName}}</label>
    </div>
    
     <div class="reason" v-if="showReason">
         <div v-for="reason in reasons" :key="reason.id">
              <button :class="rbutton(reason.id)" @click="reject(reason.id,reason.name)">{{reason.name}}</button>
         </div>
    </div>
</div>
    
</template>

<script>
export default {
    name: 'RejectButton',
    props: {
        reasons:{
          type:Array,
        },
        record:{
            type:Object
        }
    },
    data(){
        return{
            showReason:false,
            selectedName:'',
        }
    },
    methods:{
        buttonClass(btn){
            var cls="button"
            if(btn==this.record.outstatus){
                cls=cls + " buttonbordered"
            }
            return cls
        },
        reject(id,name){
            console.log('rejectme')
            this.record.rejectReasonId=id
            this.selectedName=name
            this.showReason=false
            this.record.outstatus="fail"
            this.record.rejectReasonId=id
            this.$emit('setstatus')
        },
        maybe(){
            this.showReason=false
            this.record.outstatus="maybe"
            this.$emit('setstatus')
        },
        pass(){
            this.showReason=false
            this.record.outstatus="pass"
            this.$emit('setstatus')
        },
        rbutton(id){
            if(id==this.record.rejectReasonId){return "reasonbuttonselected"}
            else{return "reasonbutton"}
        }
    }
}
</script>

<style>
.button{
  padding:5px;
   border-radius:0px;
   border:none;
   font-size:inherit;
  background-color:transparent;
  height:50px;
  margin:5px;
}
.buttonbordered{
    border:1px solid black;
}
.reason{
    display:flex;
    flex-direction: column;
    margin:0px;
    background-color: var(--fail-color);
  }
  .reasonbutton{
      margin:3px;
      width:150px;
      border:none;
      background-color: transparent;
      border-radius: 5px;
      font-size:large;
  }
  .reasonbuttonselected{
      margin:3px;
      width:150px;
      border:1px solid black;
      background-color: transparent;
      border-radius: 5px;
      font-size:large;
  }
</style>
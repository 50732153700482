<template>
  <div class="outerDiv" :style="configStyle">
        <div v-if="!loggedIn">
            <div class="header" v-if="showHeader">
                <div class="horizFlex">
                    <img alt="school logo" :src="schoolLogoFilePath"  class="logo" >
                    <label class="titleText">Remote Capture Validation</label>
                </div>
            </div>
            <loginuser ref="login" @loggedin='ready()'/>
        </div>
        <div v-if="loggedIn"> 
            <validation />
            
        </div>
        <footer class="footer">
            <!-- <div>
                <img alt="school logo" :src="footerLogoPath"  class="logo" >
            </div>
            
 -->
            <label for="">{{DesignedForText}}</label>
            <!-- <a class="footerlink" href="https://www.multicard.com" target="_blank">
                <label for="">{{poweredByText}}</label>
            </a> -->
            
            <label for="">{{footerCopyrightLabel}}</label>
            

        </footer>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import datamethods from '../datamethods'
import loginuser from './login/loginuser.vue'
import validation from './actions/validation.vue'
export default {
    components:{
        loginuser,
        validation,
    
    },
    mixins:[style, datamethods],
    computed:{

        ...mapFields([
            'loggedIn',
            'currentNav',
            'badgeData',
            'logoOverride',
            'showHeader'
        ]),
        schoolLogoFilePath(){
            if(this.logoOverride!=''){
                    return this.imageBasePath + this.logoOverride
            }else{
                return './../' + window.SCHOOL_LOGO
            }
        },
        footerCopyrightLabel(){
          if(window.FOOTER_COPYRIGHTLABEL!=undefined){
            return window.FOOTER_COPYRIGHTLABEL
          }else{return ''}
        },
    },
    data(){
        return{
            //url
            BadgeGlobalKey:'',
            EmailAddress:'',
            LocationGlobalKey:'',
            PersonGlobalKey:'',


            //config
            institutionGlobalKey:window.INSTITUTION_KEY,
            footerLogoPath:'./../' + window.FOOTER_LOGO,
            frontLinkText:window.FRONT_LINK_TEXT,
            backLinkText:window.BACK_LINK_TEXT,

            //flags
            
            imageBasePath:'',

            message:'',
            showingValidator:false,

            //data
            poweredByText:'Powered by MultiCard',
            privacyLinkText: 'For our privacy policy please visit',
            DesignedForText: 'Please note: this site is designed for a tablet or laptop, not a mobile phone.',
        }
    },
   
    methods:{
        async getImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath =  response.StringResponse
                //this.imageBasePath = window.VUE_CONFIG_API  + response.StringResponse
            }else{
                this.message = response.Message
            }
        },
        imagePath(imagePath){
            return this.imageBasePath + imagePath;
        },
        toggleCard(){
            console.log('toggle' + this.currentNav)
            if(this.currentNav=='front'){this.currentNav='back'}
            else{this.currentNav='front'}
        },
        ready(){
            this.currentNav="new"
        },
        async getBadgeDetail(){
            //setup request
            let req = {
                personId:10012345
            }
            //call api
            var response = await this.callAPI(req,'badge')
            //handle response
            if(response.Successful){
                this.badgeData=response
            }else{
                this.message = response.Message
            }
   
        }
    },
    mounted(){
        
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('BadgeGlobalKey')
        if(tmp!=null){ this.BadgeGlobalKey = tmp;}

        tmp = urlParams.get('LocationGlobalKey')
        if(tmp!=null){ this.LocationGlobalKey = tmp;}

        tmp = urlParams.get('PersonGlobalKey')
        if(tmp!=null){ this.PersonGlobalKey = tmp;}

        tmp = urlParams.get('EmailAddress')
        if(tmp!=null){ this.EmailAddress = tmp;}
        
        tmp = urlParams.get('logo')
        if(tmp!=null){ this.logoOverride = tmp;}

        tmp = urlParams.get('header')
        if(tmp!=null){this.showHeader=tmp=='true'; }

        window.onbeforeunload= function(event){
            event.preventDefault()
        }
        this.setColors();
        this.getImageBasePath();
        //this.getBadgeDetail()
        //determine if token sent over (SSO)
        tmp = urlParams.get('token')
        if(tmp!=null){
            console.log('sso!')
            this.$refs.login.post_login(tmp)
         }else{
            this.$refs.login.prelogin()
         }
    }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";

body{
  height:100vh; 
  height: -webkit-fill-available;
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:x-large;
  color:var(--text-color);
}
.outerDiv{
  margin:0px;
  padding:0px;
  height:100vh;
}
.frontDiv{
    height:100vh;
    display:grid;
    grid-template-columns:auto;
    grid-template-rows:12% 15% 36% 10% 15% 12%;
}
.backDiv{
    height:100vh;
    display:grid;
    grid-template-columns:auto;
    grid-template-rows:12% 15% 46% 15% 12%;
}

.titleText{
    font-weight: bold;
    margin-left:10px;
}

/* HEADER */
.header{
    background-color:var(--highlight-color);
    padding:10px;
}
.logo{
    height:60px;
}
.footer{
    text-align: center;
}


/* ALERT */
.alert{
    padding:10px;
}
.alertdiv{
    background-color: var(--alert-color);
    padding:10px;
}
.alerttext{
    color:var(--light-text-color);
    font-weight: bold;
}

/* STUDENT */
.studentimage{
    height:160px;
    margin:5px;
}
.studentinfo{
    width:100%;
    text-align: center;
}
.studentname{
    font-size:xx-large;
    font-weight:bolder;
    margin:5px 5px 5px 0px;
}

.studentclass{
    margin:10px;
}

/* STATUS */
.status{
    padding:20px;
}
.statustext{
    font-weight: bold;
    color:var(--alert-color);
}

/* BARCODE */
.barcode{
    padding:5px;
}

/* TOGGLE */
.toggle{
    background-color:var(--highlight-color);
    padding:10px;
}


/* INFO */
.info{
    text-align: left;
    padding:20px;
}
.infoset{
    display:flex;
    flex-direction: column;
    margin-bottom:25px;
}
.infobig{
    font-size:large;
    font-weight:bold;
}
.infolink{
    font-size:medium;
    text-decoration: underline;
}

</style>



            